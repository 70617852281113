
import './accordion.js'
import { gsap} from 'gsap'
//import 'babel-polyfill'
import { ScrambleTextPlugin } from 'gsap/ScrambleTextPlugin'
import { MotionPathPlugin } from 'gsap/MotionPathPlugin'


window.addEventListener('DOMContentLoaded', eventDom => {
 
gsap.registerPlugin(ScrambleTextPlugin)
gsap.registerPlugin(MotionPathPlugin)

const menu = document.querySelector('.nav__section')
//const subMenu = menu.querySelector('.submenu')

let subMenu 

//const toggleMenu = subMenu.previousElementSibling

const toggleMenuListener = e => {
  console.log('e.target', e.target)
  if (e.target.classList.contains('nav__item')) {
  }
  const linkNode = e.target.parentElement
  const link = linkNode.getAttribute('href')
  console.log('link', link)

  if (link === '/#docs' || link === '#hosting') {
    console.log('link', link)
    e.preventDefault()
  }

  const stateOpen = document.querySelector('.is__open')
  if ((stateOpen === null) && (e.target.closest('.nav__item '))) {
    console.log('e.target', e.target)
    subMenu = e.target.closest('.nav__item').querySelector('.submenu');
    console.log('subMenu', subMenu)
    if (subMenu !== null) {
      e.preventDefault()
      subMenu.classList.add('is__open')
      const rect = e.target.getBoundingClientRect();
      const top = rect.top + 70;
      subMenu.style.top = `${top}px`
      subMenu.style.left = `${rect.left - 8}px`
      menu.classList.add('menu__open')
    }
    return
  }
  if (!e.target.closest('.nav__subitem')) {
    console.log('e.target', e.target)
    subMenu.classList.remove('is__open')
    menu.classList.remove('menu__open')
  }
}


menu.addEventListener('click', toggleMenuListener)

/* Animation Markise */
const body = document.querySelector('body')
//const markise = document.querySelector('#objectMarkise')
const headline = document.querySelector('h1')
const boxes = document.querySelectorAll('.boxes')
const subline = headline.nextElementSibling


const fromBackgroundColor = _ => {
const htmlContent = document.querySelector('html')
const colorMode = getComputedStyle(htmlContent).getPropertyValue('content')
//console.log('colorMode', colorMode)

const fromBackgroundColor = (colorMode === "dark" || colorMode === '"dark"') ? '#000' : '#fff'
//console.log('fromBackgroundColor', fromBackgroundColor)
  return fromBackgroundColor
}
fromBackgroundColor()



const lang = document.querySelector('body')
const animationText = _ => {
  if (lang.classList.contains('en')) {
    return {
      webdesign: 'webdesign, ',
      wegagentur: 'web agency ',
      pwaagentur: 'and PWA agency',
      webentwicklung: 'web development, ',
      programmierung: 'programming'
    }
  }
  if (lang.classList.contains('de')) {
    return {
      webdesign: 'Webdesign, ',
      wegagentur: 'Webagentur ',
      pwaagentur: 'und PWA Agentur',
      webentwicklung: 'Webentwicklung',
      programmierung: 'Programmierung'
    }
  }
}

const textParts = animationText()
const animations = (fromBackgroundColor) => {

  const tl = gsap.timeline();
  // tl.from(markise, { duration: 2.3, opacity: 0.8, scaleX: 0.93, scaleY: 0.93, y: -200, ease: "none" })
  //   .from(body, { duration: 2.3, backgroundImage: `linear-gradient(336deg, ${fromBackgroundColor}, ${fromBackgroundColor})`, ease: "none" }, '-=2.8')
  //   .from(boxes, { duration: 1.3, backgroundImage: `linear-gradient(336deg, ${fromBackgroundColor}, ${fromBackgroundColor})`, ease: "none" }, '-=2.8')
  //   .from(headline, { duration: 1, opacity: 0, x: -300 }, '-=2.3')
  //   .from(subline, { duration: 1, opacity: 0, x: 300 }, '-=2.4')

  // tl.to('.homepage1', { duration: 1.2, scrambleText: { text: textParts.webdesign, chars: ' ', revealDelay: 0, tweenLength: false, speed: 2.8, delimiter: '' }, ease: "none" })
  //   .to('.homepage2', { duration: 1.2, scrambleText: { text: textParts.wegagentur, chars: ' ', revealDelay: 0, tweenLength: false, speed: 2.8, delimiter: '' }, ease: "none" })
  //   .to('.homepage3', { duration: 1.2, scrambleText: { text: textParts.pwaagentur, chars: ' ', revealDelay: 0, tweenLength: false, speed: 2.8, delimiter: '' }, ease: "none" })
  //   .to('.homepage4', { duration: 1.2, scrambleText: { text: ',', chars: ' ', revealDelay: 0, tweenLength: false, speed: 0.2, delimiter: '' }, ease: "none" })
  //   .to('.homepage5', { duration: 3, scrambleText: { text: ' ', chars: ' ', revealDelay: 0, tweenLength: false, speed: 0.2, delimiter: '' }, ease: "none" })

  const aboutMe = document.querySelector('.aboutMe')
  if (aboutMe){

  const tlAboutMe = gsap.timeline();
  tlAboutMe.to('.aboutMe', { duration: 2.5, scrambleText: { text: textParts.webentwicklung, chars: ' ', revealDelay: 1, tweenLength: false, speed: 0.4, delimiter: '' }, ease: "none" })
    .to('.aboutMe', { duration: 1.5, scrambleText: { text: textParts.programmierung, chars: ' ', revealDelay: 0.5, tweenLength: false, speed: 0.3, delimiter: '' }, ease: "none" })
}

}

animations()
animations(fromBackgroundColor)






const mobileMenuContainer = document.querySelector('.menu_icon')
const mobileOverlay = mobileMenuContainer.parentElement
const mobileMenuButton = mobileMenuContainer.children[0]
const closeButton = document.querySelector('#mobile_closebutton')
const listMobileMenu = mobileMenuContainer.nextElementSibling


mobileMenuContainer.addEventListener('click', e => {
  mobileOverlay.classList.toggle('mobile_overlay')
  listMobileMenu.classList.toggle('mobile_is_open')
 
  closeButton.classList.add('is-hidden')
  closeButton.classList.toggle('is-hidden')
  mobileMenuButton.classList.toggle('is-hidden')
  toggleMenu.removeEventListener('click', toggleMenuListener)
})

//* push notivication

// const buttonNotification = document.querySelector('.button__notification')
// if (buttonNotification) {
//   buttonNotification.addEventListener('click', async () => {
//     const permission = await Notification.requestPermission()
//     if (permission === 'granted') {
//       const notifyObj = new Notification('Push mit Mehrwert', {
//         body: 'Richtig eingesetzt bieten Push Nachrichten neue Möglichkeiten!',
//         image: '/images/icons/icon-512x512.png',
//         icon: '/images/icons/icon-152x152.png',
//         badge: '/images/icons/icon-72x72.png'
//       })
//     }
//   })
// }

const buttonPushNotification = document.querySelector('.button__notification')

if (buttonPushNotification) {
 // Add the public key generated from the console here.
// messaging.usePublicVapidKey('BKK1Noie277icWkIoja8CtbnMrsuEVNdPJ9hWeVhj5aLI-UVWTormGtugDJ6WnyNMSAY0WuK9y2um94VCQBD9LA')


  const updateSubscriptionOnServer = (token) => {
    const userToken = localStorage.getItem('pushToken')
    const isSubscribed = userToken !== null
    if (isSubscribed) {
      return database.ref('device_ids')
                .equalTo(token)
                .on('child_added', snapshot => snapshot.ref.remove())
    }

    database.ref('device_ids').once('value')
        .then(snapshots => {
          let deviceExists = false

          snapshots.forEach(childSnapshot => {
            if (childSnapshot.val() === token) {
              deviceExists = true
              return console.log('Device already registered.')
            }
          })

          if (!deviceExists) {
            console.log('Device subscribed')
            return database.ref('device_ids').push(token)
          }
        })
  }

  const permission = async _ => {
    try {
      const messaging = await firebase.messaging()
      const msg = await messaging.requestPermission(messaging)
      const token = await messaging.getToken(msg)
    // const updateServer = await updateSubscriptionOnServer(token)
      console.log(token)
      return token
    } catch (err) {
      console.log('Unable to get permission to notify.', err)
    }
  }

// Callback fired if Instance ID token is updated.
  const messaging = firebase.messaging()
  messaging.onTokenRefresh(_ => {
    messaging.getToken()
  .then(function (refreshedToken) {
    console.log('Token refreshed.')
    // Indicate that the new Instance ID token has not yet been sent to the
    // app server.
    setTokenSentToServer(false)
    // Send Instance ID token to app server.
    sendTokenToServer(refreshedToken)
    // ...
  }).catch((err) => {
    console.log('Unable to retrieve refreshed token ', err)
    showToken('Unable to retrieve refreshed token ', err)
  })
  })

  const tokenRefresh = async _ => {
    try {
      const messaging = await firebase.messaging()
      const msg = await messaging.onTokenRefresh(messaging)
      const getToken = await messaging.getToken(msg)
      setTokenSentToServer(false)
    // Send Instance ID token to app server.
      sendTokenToServer(refreshedToken)
    } catch (err) {
      console.log('Unable to retrieve refreshed token ', err)
      showToken('Unable to retrieve refreshed token ', err)
    }
  }

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker
//   `messaging.setBackgroundMessageHandler` handler.

  const hasFocusMessage = async _ => {
    const messaging = await firebase.messaging()
    messaging.onMessage((payload) => {
      console.log('Message received. ', payload)
      return self.registration.showNotification(notificationTitle,
    notificationOptions)
  // ...
    })
  }

  buttonNotification.addEventListener('click', _ => {
    permission()
  })

  const updateButtonText = _ => {
    if (Notification.permission === 'denied') {
      buttonPushNotification.textContent = 'Push Nachrichten geblockt'
      return
    }
    if (Notification.permission === 'granted') {
      buttonPushNotification.textContent = 'Push Nachrichten abonniert (abbestellen)'
    }
  }

  const initPush = async _ => {
    const messaging = await firebase.messaging()
    const msg = await messaging.requestPermission(messaging)
    const token = await messaging.getToken(msg)
    const isSubscribed = token !== null
    console.log('token', token)
    updateButtonText()
  }

  const unsubscribe = _ => {
    const messaging = firebase.messaging()
    messaging.deleteToken(userToken)
        .then(() => {
          updateSubscriptionOnServer(userToken)
          isSubscribed = false
          userToken = null
          localStorage.removeItem('pushToken')
          updateButtonText()
        })
        .catch(err => console.log('Error unsubscribing', err))
  }

  initPush()
  hasFocusMessage()
}

window.addEventListener('load', e => {
  const ohno = document.querySelector('#question')
  if (ohno){
    ohno.value = "FineF"
  }
})

// Using Intersection Observer ↓

const productKeys = document.querySelectorAll('.layout-product-key-features')
//console.log('productKeys', productKeys)

setTimeout(function () {
  document.querySelector('header').classList.add('active');
}, 300);

const observerConfig = {
  root: null,
  rootMargin: '0px 0px',
  threshold: 0.1
};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    if (entry.isIntersecting) {
      entry.target.classList.add('active');

    } else {
      entry.target.classList.remove('active');
    }
  });
}, observerConfig);

productKeys.forEach(key => {
  observer.observe(key);
});


const line1 = document.querySelector('.space #line1');

gsap.fromTo(
  line1,
  { x: '0' },
  { x: 10000, duration: 100, repeat: 0, ease: 'none', onComplete:repeatLine1 } 
);
  
function repeatLine1() {
  //console.log('repeatLine1')
  gsap.fromTo(
    line1,
    { x: '-400%' },
    { x: 10000, duration: 100, repeat: -1, ease: 'none' }
  );
}

const line_white_2 = document.querySelector('#line_white_2');


gsap.fromTo(
  line_white_2,
  { x: 0 },
  { x: 1500, duration: 8, repeat: 0, ease: 'none', onComplete:repeatWhiteLine2 } 
);
  
function repeatWhiteLine2() {
  //console.log('repeatWhiteLine2')
  gsap.fromTo(
    line_white_2,
    { x: '-10000' },
    { x: 1500, duration: 45, repeat: -1, ease: 'none' }
  );
}

const line_blue_1 = document.querySelector('.space #line_blue_1');
gsap.fromTo(
  line_blue_1,
  { x: '0' },
  { x: 10000, duration: 120, repeat: 0, ease: 'none', onComplete:repeatBlueLine1 } 
);
  
function repeatBlueLine1() {

  gsap.fromTo(
    line_blue_1,
    { x: '-300%' },
    { x: 10000, duration: 120, repeat: -1, ease: 'none' }
  );
}


const blue_line_2 = document.querySelector('.space #blue_line_2');

gsap.fromTo(
  blue_line_2,
  { x: '0' },
  { x: 5000, duration: 60, repeat: 0, ease: 'none', onComplete:repeatBlueLine2 } 
);
  
function repeatBlueLine2() {

  gsap.fromTo(
    blue_line_2,
    { x: '-600%' },
    { x: 5000, duration: 60, repeat: -1, ease: 'none' }
  );
}




gsap.set("#circle1", {xPercent: 0, yPercent: 30, scale: 1.1, autoAlpha: 1});

gsap.to("#circle1", {
  duration: 55, 
  ease: "power1.inOut",
  immediateRender: true,
  repeat: -1,
  scale: 1.1,
  motionPath: {
    path: "#pathMotion1",
    align: "#pathMotion1",
    alignOrigin: [0.5, 0.5],
   
    
    autoRotate: true
  }
});


})


